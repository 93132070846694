@import "variables";

footer{
    margin-top: 7rem;
    background: url('../assets/footer-bg.png');
    background-size: cover;
    background-color: rgba(74, 74, 74, .62);
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    @include media-breakpoint-down(md) {
        margin-top: 0;
    }
    .logo{
        width: 100%; 
        max-width: 400px;
        height: auto;
        // transform: scale(1.2);
        svg{
            color: var(--bs-yellow);
            .logo-bg{
                fill: var(--bs-green)!important; 
            }
        }
    }
    .site-map{
        .depth-0{
            margin-bottom: 2rem;
            &.has-sub{
                ul{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .nav-link{
            padding:10px 10px;
            max-width: 185px;
            font-size: 16px;
            &:hover{
                color: var(--bs-orange)!important;
            }
        }
        .depth-1{
            .nav-link{

                padding:5px 10px;
            }
        
        }
    }

    .bykeo a{
        color: #fff;
        font-size: 18px;
        
    } 
    .footer-contact, .footer-contact a{
        color: #fff;
        
    }
    .footer-contact {
        svg{
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
    }
}