
.block-image-content, .block-content-image{
    .small-icon{
        width: clamp(50px, 20vw, 226px);
        display: block;
        margin-top: -2rem;
    }
}
.block-content-bg-image{
    & > div{
        min-height: 375px;
    }
}