@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&display=swap');


@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/glightbox/dist/css/glightbox.css";

// Our own scss below

@import "header";
@import "splash";
@import "footer";
@import "blocks";
@import "page";
@import "forms";

.btn-blue{
    --bs-btn-hover-bg: #F98926;
    --bs-btn-hover-border-color: #F98926;
}
.btn-light{
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #284B6B;
    --bs-btn-hover-border-color: #284B6B;
}
.short-hr{
    display: none;
    max-width: 112px;
    width: 100%;
    height: 1px;
    background-color: #707070;
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
        max-width: 100%;
        margin-top: 2rem;
    }
}
.cke_editable{
    background-color: #dfdfdf;
}

// Apply to picture or image wrapper
.img-tint{
    background: rgba(40, 75, 107, .25);
    img{
        mix-blend-mode: multiply;
    }
}
.img-tint-hover{
    background-color: rgba(81, 81, 81, .38);
    transition: background-color .3s ease-in-out;
    img{
        mix-blend-mode: multiply;
    }
    &:hover{
       background-color: rgb(255, 255, 255); 
    }
    
}


.row-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding:0;
    margin:0;
    width: 100%;
    list-style:none;

    gap:1.5rem;
    & > *{
        display: flex;
        flex-direction: column;
     
    }
    @include media-breakpoint-down(md) {
        // flex-wrap: wrap;
        justify-content: center;
    }
}
.cta-list{
    img{
        border-radius: 50%;
        background-color: #F8F8F8;
        transition: transform .3s ease-in-out, background-color .3s ease-in-out;
        &:hover{
            transform: scale(1.1);
            background-color: $light-blue;;
        }
    }
}
.row-list-centered{
    @extend .row-list;
    justify-content: center;
}
.two-column-list{
    padding:0;
    margin:0;
    width: 100%;
    list-style:none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:1rem;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }
    li{
        display: flex;
        flex-direction: column;
        img{
            width: 100%!important;
            height: auto!important;
            margin:auto
        }
    }
}
.three-column-list{
    @extend .two-column-list;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:3rem;
    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
    li{
        position: relative;
    }
    li::after{
        right:-1.5rem;
        top:50%;
        height: 55px;
        background-color: #EBE9E9;
        width: 2px;
        transform: translateY(-50%);
        content:"";
        position: absolute;

    }
    li:last-child::after{
        display: none;
    }
}
.accordion-button{
    font-size: 2.706rem;
}
.btn{
    white-space: nowrap;
    font-size: 1.125rem;
    font-weight: 500;
    display: inline-flex;
    &::after{
        padding-left: 1rem;
        padding-top: 2px;
        content: url('../assets/next.svg');
    }
}
.collapse-menu .btn::after{
    display: none;
}
.btn-light{
    color: $blue;
}
.btn-secondary{
    --bs-btn-hover-bg: #25B88F;
    --bs-btn-hover-border-color:#25B88F;
}
.text-green{
    color:var(--bs-green);
}

.opening-hours{
    display: flex;
    flex-direction: column;
}
table td{
    padding-bottom: .5rem;
    padding-top: .5rem;
}
label.float {
    font-weight: 100;
}
.blue-a-bg{
    background: url('../assets/A_logo.png');
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #284B6B;
}

.btn-blue{
    &::after{
        filter:brightness(0)invert(1);
    }
}

ul.large-bullet{
    list-style:none;
    position: relative;
    & > li{
        padding-left: 3rem;
        
        &::before {
            content:"";
            border-radius: 50%;
            width: 23px;
            height: 23px;
            background-color: $blue;
            position: absolute;
            left:0;
        }
    }
} 


a{
    svg{
        transition: fill .3s ease-in-out; 
    }
    transition: color .3s ease-in-out;
    &:hover{
        color: $orange;
        svg{
            fill: $orange;
        }
    }
}
