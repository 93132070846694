// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";


// Theme colors
$primary: #284B6B;
$secondary: #F98926;

$light-blue: #228FF2;
$blue: #284B6B;
$navy:#000D26;
$orange: #F98926;

$brown: #924500;
$gray: #707070;
$white: #ffffff;
$enable-negative-margins: true;
// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Body
// $body-font-weight: 700;
// $font-weight-base: 700;
$headings-font-weight: 800;
// Fonts
$primary-text-emphasis: #fff;
$body-emphasis-color: #fff;
$body-color: #707070;
$font-family-base: 'Montserrat', Arial, Helvetica, sans-serif;
$font-size-base: 1rem;

// Links
$link-color: #26658d;
$link-decoration: none;
// $link-hover-color:#F98926;
// Navbar
$navbar-light-icon-color: #fff;

// Font
$font-size-root: 16px;
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

$h1-font-size: 3rem;        //48px
$h2-font-size: 2.5rem;      //40px
$h3-font-size: 2.25rem;     //36px
$h4-font-size: 1.875rem;    //30px
$h5-font-size: 1.5rem;      //24px
$h6-font-size: 1.25rem;     //20px

$lead-font-size: 1.125;     //18px
$lead-font-weight: 500;     //medium

// Navbar
$navbar-padding-y:0;
$nav-link-padding-y:1.5rem;

$nav-link-font-size:16px;
$navbar-light-color:#FFFFFF;
$navbar-nav-link-padding-x:1rem;

// Dropdown
$dropdown-color: #fff;
$dropdown-bg: $blue;
$dropdown-border-radius: 0;
$dropdown-spacer: -1px;
$dropdown-border-width:0;
$dropdown-link-active-bg: $blue;
$dropdown-min-width: 100%;

// Button
$btn-border-radius: 21px;
$btn-padding-x:1.25rem;
$btn-font-weight: 700;
$btn-color: #fff;
// Form
$focus-ring-width: 1px;
$input-border-radius:  0px;
$input-bg: transparent;
$input-focus-border-color: $brown;
$input-focus-color:  $blue;

// Accordion
$accordion-button-active-bg: #fff;
$accordion-button-color: $blue;

$accordion-button-active-bg: #fff;          
$accordion-button-active-color: $blue;

$pagination-active-color: #fff;
$pagination-active-bg: $blue;  
$pagination-active-border-color: $blue;

$navbar-toggler-border-radius:5px;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "orange":     $orange,
  "navy":       $navy,
  "blue":       $blue,
  "light":      $white,
  "brown":      $brown,
  "gray":       $gray
);