@import "variables";

header{
    .collapse.navbar-collapse{
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    .navbar-brand{
        position: relative;
        display: block;
        min-width: clamp(400px,30vw,600px);
        height: auto;
        margin:0;
        padding: 0;
        padding-right: 3rem;
        padding-left: 5rem;
        align-self: stretch;
        background-color: #fff;
        min-height: 100px;
        text-align: right;
        z-index:1001;
        img{
            // position: absolute;
            // max-width: min(35vw, 125px);
            width: 320px;
            height: 100%!important;
            // z-index: 2;
        }
    }
    .nav-link{
        height: 100%;
        display: flex;
        align-items: center;
        transition: text-shadow .2s ease-in-out;
        &:hover{
            color:#fff;
            background: $blue;
            text-shadow: 1px 0 0 #fff;
        }
    }
    .nav-item.dropdown{
        height: 100%;
        display: flex;
        align-items: center;
        &:hover{
            background: $blue;
        }
    }
    .dropdown-menu .dropdown-menu {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
  
       
    }
    .depth-0 > .nav-item > .btn-group > .dropdown-toggle-split{
        padding-left: .25rem;
        padding-right: .25rem;
    }
    .depth-1 .dropdown-toggle::after{
        margin-left: 1rem;
    }
    .dropdown-menu> li:hover > .btn-group > .dropdown-menu {
        display: block;
        
    }
    .dropdown-menu{
        margin-top: 0;
        margin-bottom:0;
        padding-top: 0;
        padding-bottom:0;
        .nav-link{
            padding: 0;
            padding-bottom: 1.5rem;
            margin: 0;
            white-space: nowrap;
        }
    }
  

    .dropdown-menu.depth-1{
        margin-top: -1rem;
    }

    .dropdown-menu::after, .dropdown-menu::before{
        position: absolute;
        z-index: 1;
        top:0;
        bottom:0;
        content:"";
        background-color: $blue;
        width:0;
        height:100%;
        
    }
    .dropdown-menu::after{
        right:0;
        padding-right:100vw;
        margin-right:-100vw;
    }
    .dropdown-menu::before{
        left:0;
        padding-left:100vw;
        margin-left:-100vw;
    }
    .nav-link.dropdown-toggle.show{
        background-color: $orange;
    }
    .dropdown-toggle-split, .dropdown-toggle{
        display: flex;
        align-items: center;
        &:hover{
            background: $blue;
        }
    }
    .btn-group{
        height: 100%;
        & > .nav-link, & > .dropdown-toggle{
            transition: background-color .15s ease-in-out;
        }
        &:hover{
            & > .nav-link, & > .dropdown-toggle{
                background: $blue;
            }
        }
        .dropdown-toggle-split:after{
            display: none;
        }
    }
    @include media-breakpoint-down(xl) {
        .navbar-brand img{
            top:0;
        }
    }
}
.offcanvas-header{
    background-color: var(--bs-primary);
    color: var(--bs-white);
}
.collapse-menu{
    .has-collapse-button{
        align-items: center;
    }
}

.site-links{
    .icon svg{
        width: 25px;
        height: 25px;
        object-fit: contain;
    }
}