@import "variables";

#splash-wrap{
    min-height: clamp(375px, 30vw, 550px); 
}

.t-home{
    #splash-wrap{
        height:100vh; 
     }
    .short-hr{
        display: block;
    }
}
.t-home, .t-contact{
    #splash-wrap .summary{
        display: none;
    }
}
.t-product{
  
    .product-list .item{
        picture{
            display: block;
            transition: background-color .3s ease-in-out;
            img{
                mix-blend-mode: multiply;
            }
        }
        h2{
            font-size: 16px;
            color:#707070;
            transition: color .3s ease-in-out;
        }
        &:hover{
            picture{
                background-color: $light-blue;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            h2{
                color:$blue;
            }
        }
        
    }
}

.t-contact{
    .contact-map{
        --bs-aspect-ratio:clamp(300px,30%,600px);
    }
    footer{
        margin-top: 0;
    }
}
.site-links{
    svg{
        height: 36px;
        width: 36px;
    }
}
.t-contact .contact-details{
    svg{
        fill: $gray;
        width: 30px;
        height: 30px;
        object-fit: contain;
   
    }
}