
.carousel-indicators {
    top: 0;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
    padding-top: calc(32vh + 5vw);
    pointer-events: none;
    gap:7rem;
    [data-bs-target] {
        width: 100px;
        height: auto;
        text-indent: 0;
        color:white;
        background-color: transparent;
        text-align: left;
        font-weight:500;
        pointer-events:all;
    }
}
.carousel-caption{
    padding-top: calc(35vh + 100px + 5vw);
    max-width: 500px;
    // transform: translateY(80%);
}
.carousel-item{
    background-color: rgba(40, 75, 107, .2);
    img{
        mix-blend-mode: multiply;
        pointer-events: none;
    }
}
.carousel-control-prev-icon{
    background-color: transparent;
    border: none;
    background-image: url("../assets/prev-arrow.svg");
    margin-right: 1.5rem;
}
.carousel-control-next-icon{
    background-color: transparent;
    border: none;
    background-image: url("../assets/next-arrow.svg");
}
.page-icon{
    background-color: #FFFFFF;
    border-radius: 50%;
    width: clamp(100px, 20vw, 165px);
    height: auto;
}